/* Estilos para WAContact */
.wa-contact {
  display: flex;
  flex-direction: column; /* Cambia a un diseño de columna para centrar el contenido */
  align-items: center;
  background-color: #f2e2ce; /* Color de fondo personalizado */
  color: #000; /* Color del texto personalizado */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 5vh;
}

.wa-link {
  text-decoration: none;
  display: flex;
  flex-direction: column; /* Cambia a un diseño de columna para centrar el contenido */
  align-items: center;
  text-align: center;
}

.wa-image img {
  width: 50px; /* Ajusta el tamaño de la imagen de WhatsApp */
  margin: 2vh 0vh;
}

.wa-text p {
  margin: 0;
  font-size: 16px;
  text-align: center;
  color: #000; /* Color del texto personalizado */
  text-shadow: #fff 1px 1px;
  font-weight: bold;
}

.wa-button {
  margin-top: 10px; /* Espacio entre el texto y el botón */
}

.button {
  background-color: #f2a7a0; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.button:hover {
  background-color: #f26e55; /* Cambia el color de fondo al pasar el mouse */
}

/* Estilos adicionales para versión de escritorio */

@media screen and (min-width: 768px) {
  .wa-image img {
    width: 80px; /* Aumenta el tamaño del icono de WhatsApp en dispositivos de escritorio */
    margin: 2vh 0; /* Ajusta el margen del icono */
  }

  .wa-text p {
    font-size: 3vh; /* Aumenta el tamaño del texto en dispositivos de escritorio */
  }

  .wa-button {
    margin-top: 20px; /* Ajusta el espacio entre el texto y el botón */
  }

  .button {
    padding: 20px 35px; /* Ajusta el tamaño del botón */
    font-size: 2.5vh;
  }
}
