/* Estilos para NavBar */
.navbar {
  background-color: #f2e2ce;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 3; /* Asegura que el menú esté en el mismo nivel que el banner */
}

.navbar.active {
  background-color: transparent;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  font-size: 24px;
  color: #000;
}

.menu-icon.active {
  color: #f2a7a0;
}

.icon-bar {
  width: 30px;
  height: 3px;
  background-color: #000;
  margin: 6px 0;
}

.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 20px;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #f2a7a0;
}

.navbar-text {
  color: #000; /* Color del texto */
  font-size: 20px; /* Tamaño del texto */
  font-weight: bold; /* Peso de la fuente */
  margin-right: auto; /* Empuja el texto hacia la izquierda para dejar espacio para el menú */
}

@media screen and (max-width: 768px) {
  .menu-icon {
    display: flex;
  }

  .nav-menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #f2e2ce;
    width: 100%;
    padding: 10px 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 2; /* Asegura que el menú esté por encima del banner */
  }

  .navbar.active .nav-menu {
    opacity: 1;
    visibility: visible;
  }

  .nav-item {
    text-align: center;
  }

  .nav-link {
    color: #000;
    font-size: 18px;
  }

  .nav-link:hover {
    color: #f2a7a0;
  }
}
