.banner-container {
  background-image: url("../../images/banner.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px #000;
}

.banner-slogan {
  font-size: 3rem;
  animation: fadeIn 2s ease-in;
}

.banner-description {
  font-size: 1.5rem;
  animation: fadeIn 2s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 768px) {
  .banner-container .banner-slogan {
    font-size: 8vh;
  }

  .banner-container .banner-description {
    font-size: 3vh;
  }
}
