/* Footer.css */
.footer {
  background-color: #333; /* Cambia el color de fondo a tu preferencia */
  color: white;
  text-align: center;
  padding: 20px;
}

.footer-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.footer-section a {
  color: #fff;
}

.footer-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center; /* Centra el título */
}

.footer-section {
  font-size: 16px;
  margin: 5px 0; /* Espacio entre secciones */
}

.footer-logo {
  width: 60px; /* Ajusta el tamaño de la imagen del logo */
  height: auto;
  margin: 10px 0; /* Espacio alrededor del logo */
}

.footer-copyright {
  font-size: 14px;
}

/* Estilos responsivos para versión desktop */
@media (min-width: 768px) {
  .footer-row {
    flex-direction: row; /* Cambia la dirección del contenedor a horizontal */
    flex-wrap: wrap; /* Envuelve los elementos en múltiples líneas si no caben en una sola fila */
    justify-content: center; /* Centra los elementos horizontalmente */
  }

  .footer-section {
    margin: 0 10px; /* Espacio entre los botones */
  }
}
