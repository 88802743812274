/* Estilos para el componente Agenda */
.agenda-container {
  background-color: #f2a7a0; /* Cambia el color de fondo según tu preferencia */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.agenda-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.section-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #fff; /* Cambia el color del círculo de ícono según tu preferencia */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.section-icon img {
  width: 35px; /* Establece el ancho deseado para las imágenes */
  height: 35px; /* Establece la altura deseada para las imágenes */
}

.section-icon img {
  max-width: 100%;
  max-height: 100%;
}

.section-content {
  color: #fff; /* Cambia el color del texto según tu preferencia */
  text-align: center;
}

h3 {
  margin: 0;
}

p {
  margin: 5px 0;
}

.section-content p a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

/* Estilos responsivos */
@media (min-width: 768px) {
  .agenda-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around; /* Distribuye los elementos horizontalmente */
    padding: 20px 40px; /* Ajusta el espaciado horizontal del contenedor */
  }

  .agenda-section {
    margin-bottom: 0;
    margin-right: 0; /* Elimina el margen derecho */
    max-width: 300px; /* Ancho máximo para cada sección */
  }
}
