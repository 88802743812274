.services-container {
  text-align: center;
  margin: 20px;
}

.services-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Para envolver los servicios en varias líneas */
  justify-content: center; /* Centra los servicios horizontalmente */
}

.service-item {
  margin: 10px 0;
  border: 1px solid #f2a7a0;
  padding: 10px;
  width: 100%;
  max-width: 400px; /* Ancho máximo para cada servicio */
  margin: 10px;
  border: 1px solid #f2a7a0;
  box-sizing: border-box; /* Incluye el padding en el ancho total */
}

.service-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.expand-icon {
  font-size: 1.5rem;
}

.service-description {
  margin-top: 10px;
}

.service-description {
  margin-top: 10px;
  overflow: hidden; /* Para ocultar su contenido cuando está contraído */
  max-height: 0; /* Inicialmente oculta la lista */
  transition: max-height 0.7s ease; /* Agrega una transición suave */
}
.service-description.active {
  max-height: 1500px; /* Altura máxima cuando está expandido */
}

.service-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.service-description li {
  text-align: left;
  padding-left: 20px; /* Ajusta este valor según sea necesario */
}

/* Nuevos estilos para los puntos */
.main-point {
  font-weight: bold;
  text-indent: 0; /* Sin sangría para los puntos principales */
}

.sub-point {
  text-indent: 10px; /* Sangría para los puntos secundarios */
  align-items: flex-start;
  font-style: italic;
}

.third-point {
  text-indent: 20px; /* Sangría para los puntos terciarios */
  align-items: flex-start;
}

/* Estilos para el contenedor de servicios */
.services-wrapper {
  max-width: 1200px; /* Ancho máximo para el contenedor en la versión desktop */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 0 20px; /* Espaciado interno */
}

/* Estilos adicionales para la versión desktop */
@media screen and (min-width: 768px) {
  .services-wrapper {
    padding: 0; /* Ajusta el espaciado interno en la versión desktop */
  }

  /* Tamaño del texto en la versión desktop */
  .services-heading {
    font-size: 28px; /* Ajusta el tamaño del título "Servicios Médicos" */
  }

  .service-title {
    font-size: 24px; /* Ajusta el tamaño de los títulos de servicios */
  }

  .service-description li {
    font-size: 18px; /* Ajusta el tamaño del texto de descripción */
  }
}
