/* Estilos para Contacto */
.contacto-container {
  text-align: center;
  margin: 20px auto; /* Centra el formulario horizontalmente */
  max-width: 100%; /* Ajusta el ancho máximo al tamaño de la pantalla */
  padding: 0 20px; /* Agrega un padding horizontal */
}

.contacto-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333; /* Color del título */
}

.contacto-container p {
  font-size: 16px;
  color: #666; /* Color del texto */
}

.contact-form {
  width: 100%;
  max-width: 600px; /* Ancho máximo del formulario */
  margin: 0 auto; /* Centra el formulario horizontalmente */
  padding: 20px; /* Espaciado interno */
  box-sizing: border-box; /* Considera el padding en el ancho total */
  background-color: #f9f9f9; /* Color de fondo del formulario */
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left; /* Alineación de texto izquierda */
}

.form-group {
  margin: 10px 0;
}

label {
  display: block;
  font-weight: bold;
  color: #333; /* Color de las etiquetas */
}

input[type="text"],
input[type="tel"],
textarea {
  width: calc(100% - 22px); /* Ajusta el ancho con margen para el borde */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 5px;
  box-sizing: border-box; /* Evitar que los paddings aumenten el ancho */
}

input[type="checkbox"] {
  margin-right: 5px;
}

button {
  background-color: #f2a7a0; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

/* Agrega estilos para el botón habilitado */
.boton-habilitado {
  background-color: #f2a7a0;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.boton-habilitado:hover {
  background-color: #f26e55;
}

/* Agrega estilos para el botón deshabilitado */
.boton-deshabilitado {
  background-color: #ccc;
  color: #666;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed;
}

button:hover {
  background-color: #f26e55; /* Cambia el color de fondo al pasar el mouse */
}

/* Media Query para pantallas pequeñas */
@media (max-width: 768px) {
  .contact-form {
    /* Reducir el relleno en pantallas pequeñas */
    padding: 10px;
    /* Ajustar márgenes laterales para pantallas pequeñas */
    margin-left: 10px;
    margin-right: 10px;
  }
}
