/* Estilos para Social */
.social-container {
  display: flex;
  justify-content: space-around; /* Espacio uniforme entre los íconos */
  align-items: center;
  background-color: #f2e2ce; /* Color de fondo personalizado */
  padding: 3vh;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.social-item {
  text-align: center;
}

.social-item a {
  text-decoration: none;
  color: #000; /* Color del texto personalizado */
  display: block;
}

.social-item img {
  width: 50px; /* Tamaño de los íconos */
  height: 50px; /* Tamaño de los íconos */
  margin: 0 auto; /* Centra los íconos horizontalmente */
}

.social-item p {
  margin: 5px 0; /* Espacio entre el ícono y el nombre de usuario */
  font-weight: bold;
}

.social-item a:hover {
  color: #f2a7a0; /* Cambia el color del texto al pasar el mouse */
}
