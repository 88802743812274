/* Estilos para el perfil profesional */
.profile {
  background-color: #f2a7a0; /* Color de fondo secundario */
  color: #fff; /* Color del texto */
  padding: 40px 0; /* Reduce el espacio en dispositivos móviles */
  text-align: center; /* Centra el contenido en dispositivos móviles */
}

.profile-container {
  display: flex;
  flex-direction: column; /* Cambia la dirección de la disposición en dispositivos móviles */
  align-items: center; /* Centra los elementos en dispositivos móviles */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.profile-image img {
  max-width: 50%; /* Reduce el tamaño de la imagen en dispositivos de escritorio */
  height: auto;
  border-radius: 50%; /* Para una imagen redonda */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombra ligera */
}

.profile-content {
  flex: 1;
  padding: 20px;
  text-align: center; /* Centra el texto en dispositivos de escritorio */
}

.profile-content h2 {
  font-size: 24px; /* Tamaño del título en dispositivos móviles */
  margin-bottom: 10px;
}

.profile-content p {
  font-size: 16px; /* Tamaño del texto secundario en dispositivos móviles */
}

@media screen and (min-width: 768px) {
  .profile {
    padding: 60px 0; /* Restaura el espacio en dispositivos de escritorio */
    text-align: left; /* Alinea el contenido a la izquierda en dispositivos de escritorio */
  }

  .profile-container {
    flex-direction: row; /* Vuelve a la disposición en fila en dispositivos de escritorio */
    justify-content: space-between; /* Espacio entre los elementos en dispositivos de escritorio */
    align-items: center; /* Centra verticalmente los elementos en dispositivos de escritorio */
  }

  .profile-image img {
    max-width: 200px; /* Tamaño de la imagen en dispositivos de escritorio */
  }

  .profile-content {
    padding: 0 20px; /* Espacio alrededor del texto en dispositivos de escritorio */
    text-align: left; /* Alinea el texto a la izquierda en dispositivos de escritorio */
  }

  .profile-content h2 {
    font-size: 28px; /* Aumenta el tamaño del título en dispositivos de escritorio */
  }

  .profile-content p {
    font-size: 18px; /* Aumenta el tamaño del texto secundario en dispositivos de escritorio */
  }
}

/* Estilos adicionales para versión de escritorio */

@media screen and (min-width: 768px) {
  /* ... (estilos existentes) */

  .profile-container {
    /* ... (estilos existentes) */
    gap: 40px; /* Aumenta el espacio entre la imagen y el texto */
  }

  .profile-image img {
    max-width: 300px; /* Aumenta el tamaño de la imagen en dispositivos de escritorio */
  }

  .profile-content {
    /* ... (estilos existentes) */
    margin-left: 40px; /* Ajusta el espacio entre la imagen y el texto */
  }

  .profile-content h2 {
    font-size: 5vh; /* Aumenta el tamaño del título en dispositivos de escritorio */
  }

  .profile-content p {
    font-size: 2.5vh; /* Aumenta el tamaño del texto secundario en dispositivos de escritorio */
  }
}
